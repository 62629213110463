// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

// project-imports
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";
import EcommerceDataChart from "sections/widget/chart/EcommerceDataChart";

// import StatCard from 'components/cards/statistics/StatCard';

// assets
import { Buildings2, Building4, Award } from "iconsax-react";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { useEffect, useState } from "react";
// import WelcomeBanner from 'sections/dashboard/default/WelcomeBanner';

const Dashboard = () => {
  const theme = useTheme();
  const [clientCount, setClientCount] = useState();
  const [standardsCounts, setStandardsCounts] = useState();
  const [previousClientCount, setPreviousClientCount] = useState(0);
  const [previousStandradCount, setPreviousStandradCount] = useState(0);

  useEffect(() => {
    getClients();
    getAllStandards();
  }, []);

  useEffect(() => {
    setPreviousClientCount(clientCount);
    setPreviousStandradCount(standardsCounts);
  }, [clientCount, standardsCounts]);

  const calculateGrowthPercentage = (currentCount, previousCount) => {
    return previousCount !== 0
      ? ((currentCount - previousCount) / previousCount) * 100
      : 0;
  };

  const getClients = () => {
    try {
      let uri = url.GET_ALL_CLIENT;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result.type === "success") {
            setClientCount(response.result.data.length);
          }
        })
        .catch((error) => {
          console.error("GET_ALL_CLIENT call Error", error);
        });
    } catch (error) {
      console.error("GET_ALL_CLIENT_API Function Error", error);
    }
  };

  const getAllStandards = async () => {
    try {
      let uri = url.ADD_STANDARD;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.result?.type == "success")
        setStandardsCounts(result.result.data.length);
    } catch (error) {
      console.error("Error fetching standards:", error);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} lg={3}>
        <EcommerceDataCard
          title="Clients"
          count={clientCount}
          path="/client/client-list"
          iconPrimary={<Buildings2 />}
          percentage={
            <Typography color="primary" variant="subtitle2">
              Growth:{" "}
              {calculateGrowthPercentage(
                clientCount,
                previousClientCount
              ).toFixed(2)}
              %
            </Typography>
          }
        >
          <EcommerceDataChart color={theme.palette.primary.main} />
        </EcommerceDataCard>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <EcommerceDataCard
          title="Standards"
          count={standardsCounts}
          path="/standards/standard-list"
          iconPrimary={<Award />}
          percentage={
            <Typography color="primary" variant="subtitle2">
              Growth:{" "}
              {calculateGrowthPercentage(
                standardsCounts,
                previousStandradCount
              ).toFixed(2)}
              %
            </Typography>
          }
        >
          <EcommerceDataChart color={theme.palette.primary.main} />
        </EcommerceDataCard>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
