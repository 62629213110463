// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  Home3,
  Profile2User,
  Box1,
  DocumentForward,
  Category2,
  MessageQuestion,
  Award,
  DocumentCode
} from "iconsax-react";

// icons
const icons = {
  dashboard: Home3,
  client: Profile2User,
  product: Box1,
  templates: DocumentForward,
  category: Category2,
  question: MessageQuestion,
  standard: Award,
  documentization: DocumentCode,
};

const contents = {
  id: "contents",
  title: <FormattedMessage id="contents" />,
  type: "group",
  children: [
    {
      id: "Clients",
      title: <FormattedMessage id="Clients" />,
      type: "item",
      url: "/client/client-list",
      icon: icons.client,
    },
    {
      id: "Products",
      title: <FormattedMessage id="Products" />,
      type: "item",
      url: "/product/product-list",
      icon: icons.product,
    },
    {
      id: "Category",
      title: <FormattedMessage id="Category" />,
      type: "item",
      url: "/category/category-list",
      icon: icons.category,
    },
    {
      id: "Templates",
      title: <FormattedMessage id="Templates" />,
      type: "item",
      url: "/template/template-list",
      icon: icons.templates,
    },
    // {
    //   id: "Questions",
    //   title: <FormattedMessage id="Questions" />,
    //   type: "collapse",
    //   icon: icons.question,
    //   children: [
    //     {
    //       id: "question-list",
    //       title: <FormattedMessage id="Questions" />,
    //       type: "item",
    //       url: "/question/question-list",
    //     },
    //     {
    //       id: "short-answer",
    //       title: <FormattedMessage id="Short Question" />,
    //       type: "item",
    //       url: "/question/short-question",
    //     },
    //     {
    //       id: "mcq-question",
    //       title: <FormattedMessage id="MCQ Questions" />,
    //       type: "item",
    //       url: "/question/mcq-question",
    //     },
    //     {
    //       id: "mcmc-question",
    //       title: <FormattedMessage id="MCMC Questions" />,
    //       type: "item",
    //       url: "/question/mcmc-question",
    //     },
    //   ],
    // },
    {
      id: "Standards",
      title: <FormattedMessage id="Standards" />,
      type: "item",
      url: "/standards/standard-list",
      icon: icons.standard,
    },
    {
      id: "Document Question",
      title: <FormattedMessage id="Document Question" />,
      type: "collapse",
      icon: icons.question,
      children: [
        {
          id: "allQuestions",
          title: <FormattedMessage id="Question List" />,
          type: "item",
          url: "/documentQuestion/allQuestions",
        },
        {
          id: "short-question",
          title: <FormattedMessage id="Short Question" />,
          type: "item",
          url: "/documentQuestion/short-question",
        },
        {
          id: "mcq-question",
          title: <FormattedMessage id="MCQ Question" />,
          type: "item",
          url: "/documentQuestion/mcq-question",
        },
        {
          id: "mcmc-question",
          title: <FormattedMessage id="MCMC Question" />,
          type: "item",
          url: "/documentQuestion/mcmc-question",
        },
        
      ],
    },
  ],
};

export default contents;
