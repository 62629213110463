
export const Constants = {

    KEY_USER_DATA: "user_data",
    KEY_NOTIFICATION_COUNT: "notification_count",
    KEY_USER_EMAIL: "user_email",
    KEY_USER_PASSWORD: "user_password",
    FIREBASE_ID: "firebase_id",
    LOCATION: "location",
    KEY_USER_ID: "user_id",
    KEY_USER_SLUG: "user_slug",
    KEY_USER_IS_FAN: "user_is_fan",
    KEY_USER_TOKEN: "user_token",
    KEY_USER_NAME: "user_name",
    KEY_USER_ADDRESS: "user_address",   
    KEY_USER_IMAGE_URL: "user_image_url",
    KEY_USER_IMAGE_NAME: "user_image_name",
    KEY_LOGIN_MODEL: "login_model",
    KEY_USER_GENDER: "user_gender",
    KEY_CHAT_MODAL: "chat_help",
    KEY_MEMBER_PLAN: "member_plan",
    KEY_IS_VERIFIED: "is_verified",
    KEY_EMAIL_VERIFIED: "email_verified",
    KEY_AUTO_RENEW: "auto_renew",
    KEY_GOLD_COINS: "gold_coins",
    KEY_NET_WORTH: "networth_amount",
    KEY_PAYMENT_STATUS: 'payment_status',
    KEY_HASH_KEY: 'hash_key',
    KEY_BANNER_ACTION: 'banner_action',
    KEY_IS_SPECIAL: 'is_special',
    KEY_MY_PROFILE: 'my_profile',
    KEY_MY_PROFILE_CONNECTIONS: 'my_profile_connections', // favorite list for me
    KEY_DASHBOARD_DATA: "dashboard_data", // data from getNearpeople api response
    KEY_PROOFID_DOC_NAME:"proofID_doc",
    KEY_ASSETS_DOC_NAME:"assets_doc",
    KEY_MODEL_DOC_NAME:"model_doc",
    KEY_ARTICLE_DOC_NAME:"article_doc",
    KEY_CONNECTION_DOC_NAME:"connection_doc",
    SELECTED_PROPERTY_ID :"selected_property_id",
    SELECTED_PROPERTY_NAME :"selected_property_name",

    NAV_TYPE_PROFILE: "profile",
    NAV_TYPE_MESSAGES: "messages",
    NAV_TYPE_MY_NETWORK: "my_network",
    NAV_TYPE_EVENTS: "event",
    NAV_TYPE_LOGOUT: "logout",

    // LABEL_EVENT_CREATED: "EVENTS\nCREATED",
    // LABEL_EVENT_JOINED: "EVENTS\nJOINED",
    LABEL_MYLISTS: "FRIEND\nLISTS",
    LABEL_ROSE_MEN: "ROSES\nSENT",
    LABEL_ROSE_WOMEN: "ROSES\nRECEIVED",
    LABEL_FAVORITES: 'MY\nFAVORITES',
    LABEL_EVENT_CREATED: "PARTIES\nCREATED",
    LABEL_EVENT_JOINED: "PARTIES\nJOINED",
    LABEL_CONNECTIONS: "CONNECTIONS",
    LABEL_FANS: "MY\nFANS",
    LABEL_PARTIES_ATTEND: "PARTIES\nATTENDED",
    LABEL_TRIPS_ATTEND: "TRIPS\nATTENDED",
    LABEL_PARTIES_HOSTED: "PARTIES\nHOSTED",
    LABEL_TRIPS_HOSTED: "TRIPS\nHOSTED",
    LABEL_REFER_FRIEND: "REFER\nFRIENDS",
    LABEL_LEADERBOARDS: "LEADER\nBOARDS",
    LABEL_GIFTS_SENT: "GIFTS\nSENT",
    LABEL_GIFTS_RECEIVED: "GIFTS\nRECEIVED",

    // LABEL_ROSE: "ROSES",
    LABEL_NEW_MEMBER_JOINTED: "New Members",
    LABEL_PEOPLE_NEAR_ME: "People Near You",
    LABEL_RECENT_LOGIN: "Most Recent Logins",
    LABEL_DISCOVER_FEATRE_ACCOUNT: "You Might Like",
    LABEL_MY_PROFILE: "My Profile",
    LABEL_GALLERY: "Gallery",
    LABEL_PROFILE_INFORMATION: "Profile Information",
    LABEL_THINGS_LIKE: "Things I Like",
    LABEL_SUCCESS_TIMELINE: "Success Timeline",
    LABEL_TRAVEL_PLANS: "Travel Plans",
    LABEL_FEEDS: "Feeds",
    LABEL_ADDED_YOU_AS_FAVOURITE: "Added You As A Favorite",
    LABEL_PEOPLE_TRAVELING_TO_YOUR_LOCATION: "People Traveling To Your Location",

    EVENT_TYPE_CURRENT_TYPE: "CURRENT EVENTS",
    EVENT_TYPE_OPEN_INVITATION: "OPEN INVITATIONS",
    EVENT_TYPE_ACCEPTED_INVITATION: "ACCEPTED INVITATIONS",
    EVENT_TYPE_MY_EVENTS: "MY EVENTS",
    EVENT_TYPE_PAST_EVENTS: "PAST EVENTS",
    LABEL_MY_ACCOUNT: "My Account",
    LABEL_REFER_FRIENDS: "New Friends",
    LABEL_MY_FAVOURITES: "My Favorites",
    LABEL_VISITED_MY_PROFILE: "Visited My Profile",
    LABEL_AGE: "Age : ",
    LABEL_ABOUT_ME: "About Me : ",
    LABEL_NET_WORTH: "Net Worth : ",
    LABEL_LOCATION: "Location : ",
    LABEL_THINK_I_LIKE: "Things I Like : ",
    LABEL_LANGUAGE: "Languages : ",
    LABEL_SHAPE_AND_COLOR: "Looks : ",
    LABEL_EVENTS: "Parties",
    LABEL_INTERESTED: "INTERESTED",
    LABEL_EDIT: "EDIT",
    LABEL_CANCEL: "CANCEL",
    LABEL_DELETE: "DELETE",
    LABEL_INVITE_FRIENDS: "INVITE FRIENDS",
    LABEL_RSVP: "RSVP",
    LABEL_SORRY: "SORRY",
    LABEL_I_WILL_ATTEND: "I WILL ATTEND",
    LABEL_NOT_SURE: "NOT SURE",
    LABEL_PENDING: "PENDING",
    LABEL_REJECTED: "REJECTED",
    LABEL_POST: "Post",
    LABEL_CREATE_GROUP: "Create Group",

    ///////////  PLEASE TEST FOLLOWING  ////////////////

    ///////// Firebase DB Tables Names /////////
    FIREBASE_LIVE_USERS_TABLE_NAME: "users",
    FIREBASE_LIVE_GROUPS_TABLE_NAME: "groups",
    FIREBASE_LIVE_GROUP_MESSAGES_TABLE_NAME: "group_messages",
    FIREBASE_LIVE_MESSAGES_TABLE_NAME: "messages",

    FIREBASE_TEST_USERS_TABLE_NAME: "test_users",
    FIREBASE_TEST_GROUPS_TABLE_NAME: "test_groups",
    FIREBASE_TEST_GROUP_MESSAGES_TABLE_NAME: "test_group_messages",
    FIREBASE_TEST_MESSAGES_TABLE_NAME: "test_messages",

    FIREBASE_MESSAGES_PER_PAGE: 10,
    ///////// Firebase DB Tables Names /////////

    // SIGN_IN_TITLE_SMALL: "ARE YOU IN THE .007%?",
    // SIGN_IN_TITLE_LARGE: "ARE YOU IN THE \n .007%?",
    SIGN_IN_TOP_BANNER: "FORGET THE 1%\nOR THE 0.1%\nWE ARE",
    SIGN_IN_TITLE_FIRST: "FORGET THE 1%,\nOR THE 0.1%,",
    SIGN_IN_TITLE_SECOND: "WE ARE",
    SIGN_IN_BOTTOM_BANNER: "SOCIALIZE AND PARTY\nWITH THE ELITE FEW",
    SIGN_IN_BOTTOM_CONTENTS: "An international circle of jet set friends bringing together the uber-rich, the famous, and the most beautiful; otherwise known as the 0.07% of society",
    SIGN_IN_CONTENTS_FIRST: "Our exclusive network joins the .007% of society—the uber-rich, famous, and stunning.",
    SIGN_IN_CONTENTS_SECOND: "Meet kindred spirits, host or join luxurious parties, and ship champagne across the globe.",
    SIGN_IN_CONTENTS_THIRD: "All members' credentials are vetted and individually approved.",
    FORGET_PWD_TITLE: "Enter your email to receive your password reset instructions",
    USER_NAME: "Username",
    PASSWORD: "Password",
    NOT_MEMBER_YET: "Not a member yet?",
    RETURN_LOGIN_PAGE: "Return to Login Page",
    FORGOT_PASSWORD: "Forgot Password?",
    ABOUT_US: "About Us",
    CLICK_HERE: "Click Here",
    SIGN_IN_COPYWRITE_TITLE: "Disclaimers",
    SIGN_IN_COPYWRITE_CONTENTS: "© 2020 by The 0.07 Percent LLC\nResources For The Top 0.07% Of The World's Population",
    FORGOT_PASSWORD_MESSAGE: "Submit your email address below and an email will be sent to you to reset your password",

    EVENT_AVAILABLE_SPACES_TOOLTIP: 'If you limit your space, only RSVPs up to the maximum number will be accepted. Additional RSVPs will be placed on a waiting list.',
    EVENT_RSVP_PERCENTAGE_TOOLTIP: 'When you specify M/F percentages, RSVPs exceeding these percentages will not be accepted; Guests will instead be asked to invite more guests of the opposite gender before they can try again.',

    EMPTY_USER_NAME: "Please enter your username",
    EMPTY_FIRST_NAME: "Please enter your first name",
    EMPTY_LAST_NAME: "Please enter last name",
    EMPTY_EMAIL_ID: "Please enter your email",
    INVALID_EMAIL_ID: "Please enter a valid email",
    INVALID_USER_NAME: "Please enter a valid username",
    EMPTY_PASSWORD: "Please enter password",
    EMPTY_NETWORTH: "Please enter your net worth",
    EMPTY_GENDER: "Please select a gender",
    EMPTY_RESIDENCE: "Please enter your residence",
    EMPTY_ADDRESS: "Please input your address",
    INVALID_PHONE_NUMBER: "Please input valid phone number",
    EMPTY_PROFILE_PICTURE: "Please select a profile picture",
    EMPTY_PROOFID_PICTURE: "Please select a valid proof of ID picture",
    EMPTY_LASTTAX_PICTURE: "Please select a last tax picture",
    EMPTY_ASSETSLIST_PICTURE: "Please select a assets list picture",
    EMPTY_DATE_OF_BIRTH: "Please enter valid date of birth",
    LIMIT_AGE: "The minimum age to join The 0.07% is 18 years",
    FORMAT: "json",
    NO_INTERNET: "Unable to process request at this time. Please try again in a few minutes.",
    INVALID_USERNAME_PASSWORD: "Could not log you in. Please check your credentials.",
    EMPTY_ABOUT_YOU: "Please input something about you",
    EMPTY_YOU_LIKE: "Please input something what you like",

    SAVE_DATA_ALERT_TITLE: "Save Data",
    SAVE_DATA_ALERT_MESSAGE: "Would you like to save changed data?",

    EMPTY_TRAVEL_YEAR: "Please enter a year",
    EMPTY_TRAVEL_DESCRIPTION: "Please enter description",
    EMPTY_SCHEDULE_FROM: "Please select a start date",
    EMPTY_SCHEDULE_TO: "Please select an end date",
    EMPTY_SCHEDULE_LOCATION: "Please enter the location you are traveling to",
    EMPTY_SCHEDULE_PURPOSE: "Please enter the purpose of this trip",

    EMPTY_EVENT_TITLE: "Please choose a title for your party",
    EMPTY_EVENT_START_DATE: "Please select a start date",
    EMPTY_EVENT_END_DATE: "Please select an end date",
    EMPTY_EVENT_LOCATION: "Please enter the location of your party",
    EMPTY_EVENT_DESCRIPTION: "Please enter a description",

    EMPTY_EVENT_START_TIME: "Please select a start time",
    EMPTY_EVENT_END_TIME: "Please select an end time",
    EMPTY_ALBUM_NAME: "Please enter a name for your album",
    EMPTY_EVENT_END_DATE_SMALL: "The end date should be after the start date",
    EMPTY_EVENT_IMAGE: "Please select an event image",
    INVALIDE_RSVP_PERCENTAGE: "Please input value Male to Female RSVP Percentage",
    THUMB_PROFILE_IMAGE: "thumb_300x300/",
    THUMB_BIG_PROFILE_IMAGE: "thumb_300x300/",
    THUMB_BIG_EVENT_IMAGE: "thumb_300x300/",
    THUMB_EVENT_IMAGE: "thumb_300x300/",
    THUMB_ALBUM_IMAGE: "thumb_300x300/",
    LABEL_VIEW_ALL: "View All",

    CALL_BACK_FUNCTION: "jqcc171016329597136555574_5",
    CALL_BACK_FUNCTION_PREFIX: "jqcc171016329597136555574_5(",
    STICKER_FILE_PREFIX: 'CC^CONTROL_',
    KICKUSER_FILE_PREFIX: 'CC^CONTROL_kicked_',
    DELETE_MESSAGE_PREFIX: 'CC^CONTROL_deletemessage_',
    MEDIA_FILE_PREFIX: '<div style=',
    SMILY_PREFIX: '<img class=\"cometchat_smiley\"',
    OTHER_FILE_PREFIX: 'has sent a file',
    OTHER_FILE_GROUP_PREFIX: 'has shared a file',
    VIEW_ALL: "VIEW\nALL",
    NO_PROFILE_MATCH_FOUND: "Your current search and filters\ndo not match any records.\nTry again.",
    ACCEPT_TERMS_CONDITIONS: "Please accept our Terms of Service and Privacy Privacy",
    LABEL_BLOCK_MESSAGE: "{0} won't be able to see your profile or posts. The .007% will not notify {0} that you have blocked them.",
    LABEL_BLOCK_TITLE: "Block {0}",
    LABEL_DELETE_POST_ALERT_TITLE: "Delete Post Confirmation",
    LABEL_DELETE_POST_ALERT_MESSAGE: "Are you sure you want to delete this post?",
    SELECT_GROUP_MEMBER:"Please select group member",
    ENTER_GROUP_NAME:"Please enter group name",
    SELECT_LANGUAGE:"Please select language",
    ENTER_COMMENT:"Please enter comment",
    SELECT_AT_LEAST_ONE_MEMBER:"Please select at least one member",
    ENTER_REASON:"Please enter reason",
    ENTER_REPLY:"Please enter reply",
    EVENT_BANNER_CHANGED: "banner_changed",
    EVENT_BANNER_INVITATION: "invitation_banner",
    EVENT_SPECIAL_INVITE_PRIVILEGE_CHANGED: "is_special_changed",
    EVENT_PROFILE_IMAGE_UPDATED:"profile_image_updated",
    EVENT_PROFILE_GOLDCOIN_UPDATED: "my_goldcoin_change",
    EVENT_NOTIFICATION_CHANGED: "my_notification_change",
    EVENT_GOTODASHBOARD: "goto_dashboard",
    EVENT_ADD_CREDIT_CARD: "add_creditcard",
    GIFT_CATEGORY_CHANGED: "gift_category_change",
    EVENT_EVENT_REFRESH: "event_refresh",
    EVENT_WEB_LINK: "web_link",

    ERROR_COMETCHAT_LOGOUT:"Cannot read property 'logout' of undefined",
    ERROR_MESSAGE_COMETCHAT_LOGOUT:"cometchat_logout",
    ERROR_NETWORK_REQUEST_FAILED: "Network request failed",
    RELOGIN_ALERT_TITLE: "Note",
    RELOGIN_ALERT_MESSAGE: "Your current session has expired. Please login again to continue.",
    NOT_APPROVED_MESSAGE: "Your account is not approved. This feature will be enabled once your account is approved.",

    DELETE_ALBUM_TITLE: "Delete Album Confirmation",
    DELETE_ALBUM_MESSAGE_PREFFIX: "Are you sure you want to permanently delete your album",
    DELETE_ALBUM_MESSAGE_SUFFIX: "and all of its images?",

    SELECT_COINS: "Please select coins you want to buy",

    INVALID_CARD_NUMBER_LENGTH: "Card number have to be 12~19 digits. Please input again",
    INVALID_CARD_NUMBER: "Please input valid card number",
    EMPTY_CARDHOLDER_FIRSTNAME: "Please input Card Holder First Name",
    EMPTY_CARDHOLDER_LASTNAME: "Please input Card Holder Last Name",
    EMPTY_CARDEXPIRATION_MONTH: "Expiration month is invalid. Please input again",
    EMPTY_CARDEXPIRATION_YEAR: "Expiration year is invalid. Please input again",
    INVALID_CVV: "Please enter the 3 digit code on the back of your card",
    INVALID_AMERICAN_CVV: "Please enter the 4 digit code on the front of your card",

    CURRENT_PASSWORD: "Please input current password",
    INVALID_PASSWORD_LENGTH: "Password have to be more than 6 characters",
    PASSWORD_MATCH: "Password does not match. Please confirm password",
    PASSWORD_UPDATE_SUCCESS: "Password updated successfully",

    INVALID_POST_CONTENTS: "Please input contents",

    EMAIL_VERIFICATION_RESENT: "Verification is sent successfully",

    NO_EVENT_MESSAGE: "The Event has been deleted",
    JOIN_REQUEST_ALERT_TITLE: "Join Request",
    JOIN_REQUEST_ALERT_MESSAGE: "Your request to join the party has been sent to the party Host. You will be notified by email once the Host confirms you",
    CANCEL_REQUEST_ALERT_TITLE: "Cancel Request",
    CANCEL_REQUEST_ALERT_MESSAGE: "You have cancelled your event participation",
    CANCEL_EVENT_ALERT_TITLE: "Cancel Event Confirmation",
    CANCEL_EVENT_ALERT_MESSAGE: "Are you sure you want to cancel this event?",
    DELETE_EVENT_ALERT_TITLE: "Delete Event Confirmation",
    DELETE_EVENT_ALERT_MESSAGE: "Are you sure to delete this event?",

    SENT_INVITE_SUCCESS: "Invitation sent successfully",
    SENT_INVITE_ALREADY: "Already sent invitation",

    PURCHASE_GOLD_COIN: "Please purchase gold coins",
    THANKS_ORDER: "Thanks for your order",
    DELETE_CARD_ALERT_TITLE: "Please confirm",
    DELETE_CARD_ALERT_MESSAGE: "Do you want to delete this card",
    REMOVE_CARD_SUCCESS: "Card removed successfully",

    SELECT_GIFT_RECEIVER: "Please select receiver",

    WARNING_ALERT_TITLE: "Warning!",
    NONINVITE_EMPTY: "Please input at least one person",

    INVITEGUEST_EMPTY: "Please input at least one person",

    INVITEUSERTOEVENT_EMPTY: "Please input at least one event",

    EMPTY_LISTNAME: "Please input list name",
    EMPTY_MEMBERLIST: "Please select member(s) you want to add",

    FAILED_MEMBERPLAN: "Failed to change member plan",

    REPORT_SUCCESS: "Reported successfully",

    ADDUSERTOLIST_EMPTY: "Please select at least one list",

    OPENING_CHATFILE: "Don't know how to open URI: ",

    NEW_MESSAGE_LIMIT: "Your account does not allow communicating with members. Would you like to upgrade to a VIP Fan account?",

    SHARE_POST_OWN_TIMELINE: "Share post to your own timeline?",

    CARD_SAVE_SUCCESS: "Card saved successfully",

    BUY_SELL_COIN_NO_SELECTED_CARD: "Please select card.",
    NO_CVV_CODE: "Please input CVV code for selected card",
    EMPTY_SELL_COIN: "Please input number of coins you want to sell",

    DELETE_ALERT_TITLE: "Delete Image Confirmation",
    DELETE_ALERT_MESSAGE: "Are you sure you want to delete image?",

    CHANGE_PLAN_ALERT_TITLE: "Change Membership Plan",
    UPGRADE_PLAN_ALERT_MESSAGE: "Are you sure to upgrade your 0.07% Member Status?",
    DOWNGRADE_PLAN_ALERT_MESSAGE: "Are you sure to downgrade your 0.07% Member Status?",

    UPLOAD_DOC_SUCCESS: "Documents uploaded successfully",
    UPLOAD_NET_WORTH_SUCCESS: "Record Updated Successfully",
    CREDENTIAL_GOLD_COINS_TOOLTIP: "Non-Members will be charged the gold value you specify here for every new conversation. This helps with reducing unsolicited messages, and allows you to generate income from Non-Members you interact with. Note: Verified Members of the “inner circle of The 0.07%” will always be able to message you for free.",

    DELETE_TIMELINE_ALERT_TITLE: "Delete Timeline Confirmation",
    DELETE_TIMELINE_ALERT_MESSAGE: "Are you sure you want to delete timeline?",
    DELETE_TRAVELPLAN_ALERT_TITLE: "Delete Travel Plan Confirmation",
    DELETE_TRAVELPLAN_ALERT_MESSAGE: "Are you sure you want to delete travel plan?",
    DELETE_ALBUM_ALERT_TITLE: "Delete Travel Plan Confirmation",
    DELETE_ALBUM_ALERT_MESSAGE: "Are you sure you want to delete travel plan?",

    INVITED_USER_SUCCESS: "An invitation has been sent to ",
    INVITE_EVENT_SELECTION: "Please select event to invite.",

    CHANGE_ACCOUNT_EXPLAIN: "Please explain why you would like to change:",

    DELETE_ACCOUNT_TITLE: "Delete Account",
    DELETE_ACCOUNT_MESSAGE: "This will fully delete your account. you will not be able to retrieve your data or reactivate your account. this is a permanent action.",
    DELETE_ACCOUNT_PASSWORD: "Enter Your Password To Delete Your Account",

    SUSPEND_ACCOUNT_TITLE: "Are You Sure You Want To Suspend Your Account?",
    SUSPEND_ACCOUNT_MESSAGE: "Your account will be suspended as of today and you will not have access to join parties, trips or travels. Your account will no longer be visible to members.\n You will be logged out after completing this action. In order to reactivate your profile, simply log in again.",

    ACCOUNT_PASSWORD_NOT_MATCH: "Your password is incorrect. Please retry.",

    INVITE_FRIEND_VIEW_HEADER: "Invite Friends Via Email And SMS",
    REFER_FRIEND_VIEW_HEADER: "Refer your friends! Tell us about the friends you would like to be considered. Invite Friends who do not have a 0.07% Profile by email, phone or Instagram",

    BRING_A_MEMBER_HEADER:"An invitation has been sent to your friend.",
    BRING_A_MEMBER_NOTE:"Important: Friends are not confirmed until they are approved by the host.",

    BRING_A_NONMEMBER_HEADER:"An invitation has been sent to your friend. Tell them to create a profile from the link they received.",
    BRING_A_NONMEMBER_NOTE:"Important: Friends are not confirmed until they have a full 0.07% profile themselves and are afterwards approved by the host.",
    
    /**********************************************************
    *   Paytm Transaction Status Error and Response Code Msgs *
    ***********************************************************/
    RESP_CODE_01 : 'Transaction Completed Successfully',
    RESP_CODE_810 : 'Transaction Cancelled or Failed',
    RESP_CODE_295 : 'Your payment failed as the UPI ID entered is incorrect.'+'\n'+'Please try again by entering a valid VPA or use a different method to complete the payment.',
    RESP_CODE_227 : 'Your payment has been declined by your bank.'+'\n'+'Please contact your bank for any queries.'+'\n'+'If money has been deducted from your account, your bank will inform us within 48 hrs and we will refund the same',
    RESP_CODE_331 : 'No Record Found',
    RESP_CODE_334 : 'Invalid Order ID',
    RESP_CODE_400 : 'Pending !'+'\n'+'Transaction status not confirmed yet',
    RESP_CODE_401 : 'Your payment has been declined by your bank.'+'\n'+'Please contact your bank for any queries.'+'\n'+'If money has been deducted from your account, your bank will inform us within 48 hrs and we will refund the same.',
    RESP_CODE_402 : 'Pending !'+'\n'+'Looks like the payment is not complete.'+'\n'+'Please wait while we confirm the status with your bank.',
    RESP_CODE_501 : 'Server Down',
    RESP_CODE_TXN_FAILED : 'Transaction Failed',
    
    /***********************************************************/
    CONOSLE_SUCCESS :'\x1b[1m\x1b[42m\x1b[30m%s\x1b[0m',
    CONOSLE_ERROR :'\x1b[1m\x1b[41m\x1b[30m%s\x1b[0m',
    CONOSLE_REQUEST :'\x1b[1m\x1b[46m\x1b[30m%s\x1b[0m',

      SUCCESS : "success",
      ERROR : "error",


};
