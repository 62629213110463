import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";
import * as url from "../services/url_helper";

// reducer - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";
// project-imports
import Loader from "components/Loader";
import axios from "axios";
import { Constants } from "services/keyConstants";
import { getSecureToken, setSecureToken } from "services/utils";
import { isEmpty } from "lodash";
import { ApiServices } from "services/apiServices";
// import { BASE_API_URL } from 'config';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        let token = getSecureToken(Constants.KEY_USER_TOKEN);
        let userData = getSecureToken(Constants.KEY_USER_DATA);
        if (isEmpty(token) && isEmpty(userData)) {
          dispatch({
            type: LOGOUT,
          });
        } else {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: userData,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const uri = url.LOGIN;
    return ApiServices.callServicePostWithBodyData(uri, {
      email,
      password,
    }).then((response) => {
      if (response?.result?.type === "success") {
        setSecureToken(Constants.KEY_USER_TOKEN, response?.result?.token);
        setSecureToken(Constants.KEY_USER_DATA, response?.result?.user);
        localStorage.setItem("userId", response.result.user._id);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: response?.result?.user,
          },
        });
      } else {
        return response?.data.result;
      }
    });
  };

  const register = async (email, password, name) => {
    const uri = url.CREATE_USER;
    return ApiServices.callServicePostWithBodyData(uri, {
      email,
      password,
      name,
    }).then((response) => {
      return response.result;
    });
  };

  const logout = () => {
    setSecureToken(Constants.KEY_USER_TOKEN, null);
    setSecureToken(Constants.KEY_USER_DATA, null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
