// project-imports
import contents from './contents';
import navigation from './navigation';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [navigation, contents]
};

export default menuItems;
