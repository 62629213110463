// clients.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchClients = createAsyncThunk('clients/fetchClients', async () => {
  // Retrieve token from session storage
  const token = localStorage.getItem('serviceToken');

  try {
    console.log('token', token);
    // Make the request with the token included in the headers
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/client/users`, {
      headers: {
        authorization: `${token}`
      }
    });
    console.log('response', response);
    return response.data.result.data; // Return only the data array
  } catch (error) {
    // Handle errors here
    console.log(error);
    throw Error(error.message);
  }
});

// Define the initial state
const initialState = {
  clients: [],
  loading: false,
  error: null
};

// Create a slice for managing client data
const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default clientSlice.reducer;
