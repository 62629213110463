import { isEmpty } from "lodash";
import { Constants } from "./keyConstants";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import { Box } from "@mui/material";

export const isUserLoggedIn = () => {
  let token = getSecureToken(Constants.KEY_USER_TOKEN);
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  if (isEmpty(token) && isEmpty(userData)) {
    return false;
  } else {
    return true;
  }
};

export const setSecureToken = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getSecureToken = (key) => {
  const data = localStorage.getItem(key);
  if (data !== null) {
    return JSON.parse(data);
  } else {
    return false;
  }
};
export const extractContentFromHTML = (html) => {
  var span = document.createElement("span");
  span.innerHTML = html;
  return span.textContent || span.innerText;
};
export const logout = () => {
  localStorage.clear();
  window.location.replace("/#/login");
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const dispatchFunction = (response) => {
  dispatch(
    openSnackbar({
      open: true,
      message: response.result.message,
      variant: "alert",
      alert: {
        color: response.result.type == "success" ? "success" : "error",
      },
      close: false,
    })
  );
};

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

export function formatKey(key) {
  return (
    key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .charAt(0)
      .toUpperCase() + key.slice(1).replace(/[A-Z]/g, " $&")
  );
}

export function formatKeyToCameCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
