// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";

import { useEffect, useState, useRef } from "react";

import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
// ==============================|| STANDARD LIST||============================== //

const QuestionStandardList = () => {
  const theme = useTheme();

  const standardName = useRef(localStorage.getItem("standardName"));
  const [getQuestions, setQuestions] = useState();

  const getQuestionList = async () => {
    try {
      let reqObj = {
        standard: standardName.current,
      };
      let uri = url.GET_STD_QUESTION;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if(result.response) setQuestions(result.result.data);
    } catch (error) {
      console.error("GET_ALL_QUESTION Function Error", error);
    }
  };

  const DynamicAccordion = () => {
    return (
      <>
        {Object.entries(sections).map(([sectionTitle, sectionData]) => (
          <Accordion key={sectionTitle}>
            <AccordionSummary
              aria-controls={`${sectionTitle}-content`}
              id={`${sectionTitle}-header`}
            >
              <Typography variant="h6">{sectionTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {Object.values(sectionData.questions).map(
                  (questionData, index) => (
                    <Box
                      key={questionData._id}
                      sx={{
                        "& .MuiAccordion-root": {
                          borderColor: "white", //for border color
                          "& .MuiAccordionSummary-root": {
                            bgcolor: "transparent",
                            flexDirection: "row",
                          },
                          "& .MuiAccordionDetails-root": {
                            borderColor: "white", //for border color
                          },
                          "& .Mui-expanded": {
                            color: "grey",
                          },
                        },
                      }}
                    >
                      <Accordion expanded={true}>
                        <AccordionSummary>
                          <Typography>
                            {`${index + 1}) ${
                              questionData.question.questionContent
                            }`}
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                      <Divider />
                    </Box>
                  )
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  };

  const sections = {};

  // Loop through the data array
  getQuestions?.forEach((item) => {
    const { section } = item.question;

    // If the section doesn't exist in sections object, create it
    if (!sections[section]) {
      sections[section] = { questions: {} };
    }
    // Add the question to its corresponding section using _id as key
    sections[section].questions[item._id] = item;
  });

  useEffect(() => {
    getQuestionList();
  }, []);

  return (
    <MainCard content={false}>
      <ScrollX>
          <DynamicAccordion />
      </ScrollX>
    </MainCard>
  );
};

export default QuestionStandardList;
